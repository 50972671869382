import axios, { AxiosError } from "axios";
// import * as Sentry from "@sentry/react"; // ??? 有問題 !!! "typescript": "^4.0.3",
export interface Response<T> {
  error?: AxiosError<{
    data: { message: string; errors: { [key: string]: string } };
  }>;
  data?: T;
}
console.log(process.env.REACT_APP_Test);
console.log(process.env.REACT_APP_API_URL)
const apiURL = process.env.REACT_APP_API_URL; // 2021.12.10 Mike
// const apiURL = 'http://localhost'; // 小白

export const vipAxios = axios.create({
  baseURL: `${apiURL}`, // 2021.12.10 Mike
});

vipAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error.response.status);

    // 小白隱藏
    // const errorJson = error.toJSON();
    // const apiUrl = errorJson.config.url.slice(
    //   0,
    //   errorJson.config.url.indexOf("?") !== -1
    //     ? errorJson.config.url.indexOf("?")
    //     : errorJson.config.url.length
    // );
    
    // if (error.request.responseURL.indexOf("/api/member/login") === -1) {
    //   (Sentry as any).configureScope((scope:any) => {
    //     Sentry.setTag("api", apiUrl);
    //     scope.setFingerprint([
    //       errorJson.config.method,
    //       apiUrl,
    //       `${error.response.status}`,
    //     ]);
    //     scope.setContext("error response", error.response);
    //     Sentry.captureException(error);
    //   });
    // } else if (error.response.status !== 404) {
    //   (Sentry as any).configureScope((scope:any) => {
    //     Sentry.setTag("api", apiUrl);
    //     scope.setFingerprint([
    //       errorJson.config.method,
    //       apiUrl,
    //       `${error.response.status}`,
    //     ]);
    //     scope.setContext("error response", error.response);
    //     Sentry.captureException(error);
    //   });
    // }
    throw error;
  }
);

interface LoginType {
  data: {
    mobile_number: any;
    token: string;
    expire_in: number;
    token_type: string;
    // 一般用戶 1 正式用戶 0
    is_common: 0 | 1;
  };
}

/**
 * 會員登入
 * @param lineToken line ID Token
 */
export const Login = async (
  lineToken: string,
  brandID: number
): Promise<Response<LoginType>> => {
  try {
    const { data } = await vipAxios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface BrandInfo {
  id: number;
  liff_id: string;
  name: string;
  user_agreement: string;
  brand_code: string;
  identifier: string;
  required_registration_toggles: {
    user_email: number;
    user_gender: number;
    user_city: number;
    user_job: number;
    user_edu: number;
    user_mrg: number;
    user_hbt: number;
    user_diet: number;
    user_birth: number;
    user_name: number;
  };

  brand_modules: {
    // 集點卡
    loyalty_card: 1 | 0;
    // 會員卡
    member_card: 1 | 0;
    // 入會禮
    welcome_gift: 1 | 0;
    // 生日禮
    birth_gift: 1 | 0;
    // 優惠券
    coupon: 1 | 0;
    // 商店
    store: 1 | 0;
    // 外部活動
    exteranl_activity: 1 | 0;
    // 核銷編號
    show_deduct_no: 1 | 0;
    // 票券核銷方式 1印章 2條碼
    type_deduct: 1 | 2;
  };
  menu: { id: string; title: string }[];
  menu_color: string;
}
interface BrandInfoRes {
  data: BrandInfo;
}
/**
 * 獲取品牌相關設定
 * - liff id
 * - menu order
 * - main olor
 * @param brandID 品牌ID
 */
export const getBrandInfo = async (
  brandID: string
): Promise<Response<BrandInfo>> => {
  try {
    const { data } = await vipAxios.request<BrandInfoRes>({
      url: `/api/member/brands/${brandID}`,
    });
    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface MemberRegister {
  data: {
    message: string;
  };
}

type RegisterFormData = {
  mobile_number?: string;
};

interface RegisterResponse<T> {
  error?: AxiosError<{ message: string; errors: { [key: string]: string } }>;
  data?: T;
}

/**
 * 會員註冊
 * @param lineToken line token
 * @param formData 註冊資料
 */
export const postRegister = async (
  lineToken: string,
  brandID: number,
  formData: RegisterFormData
): Promise<RegisterResponse<MemberRegister>> => {
  try {
    const { data } = await vipAxios.request<MemberRegister>({
      url: `/api/member/signup`,
      method: "POST",
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });

    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
