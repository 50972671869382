import { LiffProvider } from "context/Liff";
import AppWrapper from "AppWrapper";

const LIFFID = `${process.env.REACT_APP_LIFFID}`;
// const LIFFID = '1657054151-zJYZ6aKp';

//  20230201 wilson add
//  阻擋console.log
console.log = function () {};

function App() {
  return (
    <LiffProvider liffId={LIFFID}>
      <AppWrapper />
    </LiffProvider>
  );
}

export default App;
