import { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const storage = {};
  const [ token, setToken ] = useState(""); // 權杖、通關密碼 (沒用到) 已挪到 storage/user.js
  const [ user, setUser ] = useState([]); // User Data (沒用到) 已挪到 storage/user.js
  const [ channel_name, setChannelName ] = useState(); // 通路名稱
  const [ channel_id, setChannelId ] = useState(); // 通路ID
  const [ use_type, setUseType ] = useState(); // 通路.use_type
  const [ svpt_name, setSvPtName ] = useState(); // 服務據點名稱
  const [ svpt_id, setSvPtId ] = useState(); // 服務據點ID
  const [ epk_id, setEpkId ] = useState(); // 環保包材編號
  const [ type_name, setTypeName ] = useState(); // 環保包材類型名稱
  const [ date_time, setDateTime ] = useState(); // 使用者租還記錄.DateTime
  const [ renter_id, setRenterId ] = useState(); // 使用者ID // 21.12.18 SCH
  const [ store_id, setStoreId ] = useState(); // ServicePoint 的 store_id // 21.12.23 SCH
  const [ chs_short_code, setChsShortCode ] = useState(); // Channel 的 short_code
  const [ register_to_home, setRegisterToHome ] = useState(false); // 22.02.11

  const [ fami_tage, setFamiTage ] = useState(false); // 20230110 wilson add
  const [ gps_tage, setGpsTage ] = useState(false); // 20230110 wilson add

  // 22.02.18 SCH, add for Nearby.backToHome, // 預設位置 配客嘉公司所在
  // const [ me_latitude, setMeLatitude ] = useState(25.04939697003468);
  // const [ me_longitude, setMeLongitude ] = useState(121.52092431005671);
  const [ me_latitude, setMeLatitude ] = useState(0);
  const [ me_longitude, setMeLongitude ] = useState(0);

    // 20230120 wilson test
    const [ fami_me_latitude, setFamiMeLatitude ] = useState();
    const [ fami_me_longitude, setFamiMeLongitude ] = useState();
    const [ fami_from, setFamiFrom ] = useState(0);

  // 22.04.27 SCH, add for Nearby.searchShop
  const [ channels, setChannels ] = useState([]); // 通路資料 // 22.05.20 SCH
  // 22.05.16 SCH, add for Nearby.searchShop
  const [ picture_url, setPictureUrl ] = useState(""); // 通路兌換券.url
  const [ description, setDescription ] = useState(""); // 通路兌換券.description
  // 22.07.02 SCH, add for Nearby.searchShop
  const [logo_url, setLogoUrl ] = useState(""); // 通路.url
  const [ info, setInfo ] = useState(""); // 通路.info
  // 22.05.21 SCH, add for Nearby.searchShop
  const [ spAreaAry, setSpAreaAry ] = useState([]); // 服務據點查詢距離範圍資料
  const [ chkDistance, setChkDistance ] = useState(0); // 服務據點查詢距離設定資料
  const [ is_area, setIsArea ] = useState(0); // 服務據點查詢是否距離設定
  // 22.10.18 SCH, add for PaymentSetting.paymentMember
  const [ paymentAccessToken, setPaymentAccessToken ] = useState(""); // Line pay reserve paymentAccessToken
  const [ paymentUrlWeb, setPaymentUrlWeb ] = useState(); // Line pay reserve paymentUrlWeb
  const [ transationId, setTransationId ] = useState(""); // Line pay reserve transationId
  const [ orderId, setOrderId ] = useState(""); // Line pay confirm orderId
  const [ regKey, setRegKey ] = useState(""); // Line pay confirm regKey

  // const [ vip_dev ] = useState('https://liff.line.me/1656691395-L73dr9A8'); // 22.02.10 SCH
  // const [ vip_beta ] = useState('https://liff.line.me/1656708364-BrMpjqNz'); // 22.02.10 SCH
  // const [ vip_main ] = useState('https://liff.line.me/1656716575-p8BEzWLR'); // 22.02.10 SCH


  const [ vip_dev ] = useState('https://liff.line.me/1656716575-p8BEzWLR'); // 22.02.10 stage
  const [ vip_beta ] = useState('https://liff.line.me/1656716575-p8BEzWLR'); // 22.02.10 stage
  const [ vip_main ] = useState('https://liff.line.me/1656716575-p8BEzWLR'); // 22.02.10 stage

  // 這段是 dev line ID Wilson
  // const [ vip_dev ] = useState('https://liff.line.me/1657716680-j5zWvLOG'); //  20230201 wilson add stage
  // const [ vip_beta ] = useState('https://liff.line.me/1657716680-j5zWvLOG'); // 20230201 wilson add stage
  // const [ vip_main ] = useState('https://liff.line.me/1657716680-j5zWvLOG'); // 20230201 wilson add stage


  //這段是 stage line ID Wilson
  // const [ vip_dev ] = useState('https://liff.line.me/1657865689-xq9GVlDL'); // 22.02.10 dev
  // const [ vip_beta ] = useState('https://liff.line.me/1657865689-xq9GVlDL'); // 22.02.10 dev
  // const [ vip_main ] = useState('https://liff.line.me/1657865689-xq9GVlDL'); // 22.02.10 dev





  storage['linePayStorage'] = {
    paymentAccessToken, setPaymentAccessToken,
    paymentUrlWeb, setPaymentUrlWeb,
    transationId, setTransationId,
    orderId, setOrderId,
    regKey, setRegKey, // 22.10.18 SCH
  }

  storage['lineStorage'] = {
    vip_dev, vip_beta, vip_main,
  }

  storage['nearByStorage'] = {
    // 22.02.18 SCH
    me_latitude, setMeLatitude,
    me_longitude, setMeLongitude,
    // 22.04.27 SCH
    channels, setChannels,
    // 22.05.16 SCH
    picture_url, setPictureUrl,
    description, setDescription,
    // 22.07.02 SCH
    logo_url, setLogoUrl,
    info, setInfo,
    // 22.05.21 SCH
    spAreaAry, setSpAreaAry,
    chkDistance, setChkDistance,
    is_area, setIsArea,
    gps_tage, setGpsTage, // 20230101 wilson add

    // 20230120 wilson add
    fami_me_latitude, setFamiMeLatitude,
    fami_me_longitude, setFamiMeLongitude,
    fami_from, setFamiFrom,
  }

  // 22.11.02 SCH, 已挪到 storage/user.js
  storage['userStorage'] = {
    token, setToken, // (沒用到)
    user, setUser, // (沒用到)
  }

  storage['returnStorage'] = {
    channel_name, setChannelName,
    channel_id, setChannelId,
    use_type, setUseType, // 21.12.21 SCH
    svpt_name, setSvPtName,
    svpt_id, setSvPtId,
    epk_id, setEpkId,
    type_name, setTypeName,
    date_time, setDateTime,
    renter_id, setRenterId, // 21.12.18 SCH
    store_id, setStoreId, // 21.12.23 SCH
    chs_short_code, setChsShortCode,
    register_to_home, setRegisterToHome, // 22.02.11 SCH
    fami_tage, setFamiTage, // 20230101 wilson add
  }

  return (
    <GlobalContext.Provider value={storage}>
      {props.children}
    </GlobalContext.Provider>
  )
}
