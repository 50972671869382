import { FC, useRef, useContext } from "react"; // 20230112 wilson add
import styles from "./index.module.scss";
import { GlobalContext } from "../../storage"; // 20230112 wilson add
import FamiReturnTipsPNG from "assets/fami_return_tips.png"; // 20230113 wilson add

type StepperProps = {
  step: number;
};

const Stepper: FC<StepperProps> = ({ step }) => {
  const { returnStorage } = useContext(GlobalContext); // 20230112 wilson add
  const { fami_tage, setFamiTage } = returnStorage; // 20230112 wilsom add

  console.log(setFamiTage);

  const stepperRef = useRef<HTMLDivElement>(null);

  const indicatorStyle = {
    transform: `translateX(${
      step === 0
        ? 0
        : step === 1
        ? (stepperRef.current?.clientWidth! - 20) / 2
        : stepperRef.current?.clientWidth! - 20
    }px)`,
  };
  return (
    <div ref={stepperRef} className={styles.stepper}>
      <div className={styles.steps}>
        <div
          className={
            step === 0 ? `${styles.step} ${styles.active}` : styles.step
          }
        >
          STEP 1
        </div>
        <div
          className={
            step === 1 ? `${styles.step} ${styles.active}` : styles.step
          }
        >
          STEP 2
        </div>
        <div
          className={
            step === 2 ? `${styles.step} ${styles.active}` : styles.step
          }
        >
          完成
        </div>
        <div className={styles.indicator} style={indicatorStyle}></div>
      </div>
      <div className={styles.content}>
        <span
          className={
            step === 0 ? `${styles.stepDesc} ${styles.active}` : styles.stepDesc
          }
        >
          請掃描「歸還點」上的 QR Code
          <ul>
            <li>全家便利商店：至 FamiPort 機台點選服務寄件，找到配客嘉循環包裝選項。</li>
            <li>其他歸還點：至店內找尋或詢問店員循環箱位置。</li>
          </ul>
        </span>
        <span
          className={
            step === 1 ? `${styles.stepDesc} ${styles.active}` : styles.stepDesc
          }
        >
          請掃描「包裝」上的 QR Code
        </span>
        <span
          className={
            step === 2 && fami_tage !== true
              ? `${styles.stepDesc} ${styles.active}`
              : styles.stepDesc
          }
        >
          點擊上方按鈕結束歸還，領取優惠
        </span>

        {/* 20230112 wilosn add */}
        <span
          className={
            step === 2 && fami_tage === true
              ? `${styles.stepDesc} ${styles.active}`
              : styles.stepDesc
          }
        >
          {fami_tage ? (
            <img
              style={{ width: 300, height: 190, left: 7, top: 96 }}
              src={FamiReturnTipsPNG}
              alt="PNG"
            />
          ) : (
            <p></p>
          )}
        </span>
      </div>
    </div>
  );
};

export default Stepper;
